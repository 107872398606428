import "./footer.scss";
import { SimpleLogo, Facebook, Instagram, Twitter, LinkedIn, Logo } from "../../images";
import { SectionIds } from "../../common/constants";

const LogoContainer = ({ logo }) => {
  return (
    <div className='logo'>
      <img src={logo.Logo} alt='logo' />
      <div className='heading'>{logo.heading}</div>
    </div>
  );
};

const Navigation = ({ navigation }) => {
  return (
    <div className='navigation'>
      <div className='heading'>{navigation.heading}</div>
      {
        navigation.links.map(item => (
          <div
            className={'subheading'}
            onClick={() => {
              let ref = document.getElementById(item.id);
              window.scrollTo({ top: ref.offsetTop, left: 0, behavior: "smooth" });
            }}
          >
            {item.name}
          </div>
        ))
      }
    </div>
  );
};

const Detail = ({ details }) => {
  return (
    <div className='details'>
      <div className='heading'>{details.heading}</div>
      <div className='subheading'>{details.phone2}</div>
      <div className='subheading'>{details.phone}</div>
      <div className='subheading'>{details.mail}</div>
    </div>
  );
};

const Contact = ({ contact }) => {
  return (
    <div className='contact'>
      {
        contact.map(item => (
          <a href={item.link} target="_blank">
            <div className='image-container'>
              <img src={item.image} alt={item.name} />
            </div>
          </a>
        ))
      }     
    </div>
  );
};
const Footer = ({ copyright, logo, navigation, details, contact }) => {
  return (
    <div id={SectionIds.footer} className='footer'>
      <div className='footer_upper_web'>
        <div>
          <LogoContainer logo={logo} />
          <Navigation navigation={navigation} />
        </div>
        <div>
          <Detail details={details} />
          <Contact contact={contact} />
        </div>
      </div>
      <div className='footer_upper_mobile'>
        <LogoContainer logo={logo} />
        <div className="contact-wrapper">
          <Navigation navigation={navigation} />
          <Detail details={details} />
        </div>
        <Contact contact={contact} />
      </div>
      <div className='line' />
      <div className='footer_lower'>
        <img src={Logo} alt='logo' />
        <div className='copyright'>{copyright}</div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  copyright: "© 2012 scoremore  |   All rights reserved. Privacy Policy",
  logo: {
    Logo: SimpleLogo,
    heading: "Providing online homework/ assignment across all subject by professional tutors."
  },
  navigation: {
    heading: "Site Navigation",
    links: [
    {
      id: SectionIds.main,
      name: "About"
    },
    {
      id: SectionIds.courses,
      name: "Subjects"
    },
    {
      id: SectionIds.whyus,
      name: "Why us"
    },
    
  ],
    about: "About",
    work: "Why us",
    faq: "Subjects",
  },
  details: {
    heading: "Contact",
    phone: "+91-9953193725",
    phone2: "+1 518-333-5888",
    mail: "scoremore.info@gmail.com"
  },
  contact: [
    {name: "Facebook",image: Facebook, link: 'https://www.facebook.com/Scoremore-101399182379669/'},
    {name: "Instagram",image: Instagram, link: 'https://instagram.com/scoremore.info'},
    {name: "Twitter",image: Twitter, link: 'https://twitter.com/ScoreMore07'},
    {name: "LinkedIn",image: LinkedIn, link: 'https://linkedin.com/in/score-more-3228b9225'},
  ]
};

export default Footer;
