import React from "react";
import Phone from "../../images/phone_header.svg";
import Mail from "../../images/mail.svg";
import IndiaFlag from "../../images/india.svg";
import USFlag from "../../images/united-states.svg";

const HeaderBanner = ({ email, phone, phone2 }) => {
  return (
    <div id='header_banner' className='header_banner'>
      <div>
        <img src={Mail} alt='email' />
        <div className='header_banner_heading'>
          <p className='header_banner_heading-main'>Email:</p>
          <p className='header_banner_heading-desc'>{email}</p>
        </div>
      </div>
      <div>
        <img src={Phone} alt='email' />
        <div className='header_banner_heading'>
          <p className='header_banner_heading-main'>Phone:</p>
          <div className='header_banner_section'>
            <div className='header_banner_sub_section'>
              <img src={USFlag} alt='usFlag' className='header_country_flag' />
              <p className='header_banner_heading-desc'>{phone}</p>
            </div>
            <div className='header_banner_sub_section'>
              <img
                src={IndiaFlag}
                alt='indiaFlag'
                className='header_country_flag'
              />
              <p className='header_banner_heading-desc'>{phone2}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeaderBanner.defaultProps = {
  email: "scoremore.info@gmail.com",
  phone: "+1 518-333-5888",
  phone2: "+91-9953193725",
};
export default HeaderBanner;
