import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Submit from "../submit-modal";
import "./form.scss";
import { SectionIds } from "../../common/constants";
import EmailValidation from "./email-validator";
import PhoneValidation from "awesome-phonenumber";
import { TextInput, Datetime, CheckboxInput, Slider, TextArea, Button } from "../../components";
import emailjs from "emailjs-com";

let DEFAULT_STATE = {
  name: "",
  email: "",
  subject: "",
  date: new Date(),
  mobile: "",
  unformatted: "",
  country: "",
  side: "single",
  page: 0,
  desc: "",
  showValidation: false,
  loading: false,
  submitted: false,
  showMobileTooltip: false,
  showEmailTooltip: false
};

const ValidationText = "Please fill all * mandatory field";
class Form extends React.Component {
  state = { ...DEFAULT_STATE };
  user_id = "user_Y1EVKLlk2ZR60ipbmO9BA";

  onChange = (field, value) => {
    this.setState({ [field]: value, showValidation: false });
  };

  addEvent = () => {
    this.setState({
      showEmailTooltip: false,
      showMobileTooltip: false,
      showValidation: false
    });
    document.body.removeEventListener("click", this.addEvent);
  };

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    let { mobile, email, showEmailTooltip, showMobileTooltip, unformatted, country } = this.state;
    let pn = new PhoneValidation(unformatted, country.countryCode);
    if (
      !mobile ||
      !email ||
      showEmailTooltip ||
      showMobileTooltip ||
      (email && !EmailValidation(email)) ||
      (mobile && !pn.isValid())
    ) {
      this.setState(
        {
          showValidation: true,
          showEmailTooltip: !email || showEmailTooltip || (email && !EmailValidation(email)),
          showMobileTooltip: !mobile || showMobileTooltip || (mobile && !pn.isValid())
        },
        () => {
          document.body.addEventListener("click", this.addEvent);
        }
      );

      return null;
    }
    this.setState({ loading: true });
    emailjs.send("service_bppg67m", "template_i2kds5m", this.state, this.user_id).then(
      result => {
        this.setState({ ...DEFAULT_STATE }, () => {
          this.setState({ submitted: true, loading: false });
          setTimeout(() => {
            this.setState({ submitted: false });
          }, 3000);
        });
      },
      error => {
        this.setState({ ...DEFAULT_STATE });
      }
    );
  };

  render() {
    let {
      side,
      date,
      page,
      name,
      email,
      subject,
      desc,
      mobile,
      country,
      showValidation,
      loading,
      submitted,
      showMobileTooltip,
      showEmailTooltip,
      unformatted
    } = this.state;
    let emailValidationText = email
      ? "Please provide the correct email address like 'xxx@zz.com'"
      : "Please provide your email";
    let phoneValidationText = mobile
      ? "Please provide mobile number is not correct"
      : "Please provide your mobile number";

    return (
      <div id={SectionIds.form} className='form'>
        {loading && <Submit loading={true} />}
        {submitted && <Submit loading={false} />}
        <div className='row'>
          <TextInput placeholder='Name' field='name' value={name} onChange={this.onChange} />
          <TextInput
            placeholder='Email Id *'
            field='email'
            value={email}
            onChange={this.onChange}
            showTooltip={showEmailTooltip}
            tooltip={emailValidationText}
            onBlur={() => {
              if (EmailValidation(email) && showEmailTooltip) {
                this.setState({ showEmailTooltip: false });
              } else if (!EmailValidation(email)) {
                this.setState({ showEmailTooltip: true });
              }
            }}
          />
        </div>
        <div className='row'>
          <TextInput
            placeholder='Subject'
            field='subject'
            value={subject}
            onChange={this.onChange}
          />
          <Datetime field='date' startDate={date} value={date} onChange={this.onChange} />
        </div>
        <div className='phone'>
          <PhoneInput
            country={"in"}
            value={mobile}
            onChange={(value, country, e, formattedValue) => {
              this.onChange("mobile", formattedValue);
              this.onChange("unformatted", value);
              this.onChange("country", country);
            }}
            containerStyle={{
              background: "red",
              margin: 2,
              marginBottom: 18,
              borderRadius: 12
            }}
            onBlur={() => {
              let pn = new PhoneValidation(unformatted, country.countryCode);
              if (pn.isValid() && showMobileTooltip) {
                this.setState({ showMobileTooltip: false });
              } else if (!pn.isValid()) {
                this.setState({ showMobileTooltip: true });
              }
            }}
            inputClass='headings_input_text'
            inputStyle={{
              width: "100%",
              height: 56
            }}
          />
          {showMobileTooltip && <div className='phone-tooltip'>{phoneValidationText}</div>}
        </div>
        {/* <TextInput
          placeholder='Mobile Number *'
          field='mobile'
          type='number'
          value={mobile}
          onChange={this.onChange}
        /> */}
        {/* <div className='checkbox-selection'>
          <CheckboxInput
            isSelected={side === "single"}
            onClick={() => {
              this.onChange("side", "single");
            }}
          >
            Single Side
          </CheckboxInput>
          <CheckboxInput
            isSelected={side === "double"}
            onClick={() => {
              this.onChange("side", "double");
            }}
          >
            Double Side
          </CheckboxInput>
        </div> */}
        <div id='form-center' className='slider-heading'>
          Select Word Count
        </div>
        <Slider field='page' onChange={this.onChange} value={page} />
        <div className='description'>
          <TextArea field='desc' placeholder='Description…' value={desc} onChange={this.onChange} />
        </div>
        {showValidation && <p className='error-text'>{ValidationText}</p>}
        <Button id='form-submit' label='Submit' callBack={this.onClick} />
      </div>
    );
  }
}

const Headings = ({ primary, secondary }) => {
  return (
    <div className='headings'>
      <div className='primary'>{primary}</div>
      <div className='secondary'>{secondary}</div>
    </div>
  );
};

const FormContainer = ({ headings }) => {
  let { submited, setSubmit } = useState(false);
  return (
    <div className='form-wrapper'>
      <div id={SectionIds["form-container"]} className='form-container'>
        <Headings {...headings} />
        <Form />
      </div>
    </div>
  );
};

FormContainer.defaultProps = {
  headings: {
    primary: "Guaranteed satisfaction & Timely delivery",
    secondary:
      "Get Help Today… By Our Services. Tell us about the problem of your by fill this form."
  }
};

export default FormContainer;
