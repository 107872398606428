import "./chat.scss";
import { WhatsApp } from "../../images";

const Chat = ({ text, img, url }) => {
  return (
    <div
      className='chat-with-us'
      onClick={() => {
        window.open(url, "_blank");
      }}
    >
      <img {...img} />
      <p>{text}</p>
    </div>
  );
};

Chat.defaultProps = {
  img: {
    src: WhatsApp,
    alt: "chat"
  },
  url: "https://api.whatsapp.com/send?phone=+15183335888&text=Hey!%20I%20need%20assignment%20help",
  text: "Chat with us..."
};

export default Chat;
