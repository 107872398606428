export const SectionIds = {
  main: "main",
  courses: "courses",
  faq: "faq",
  footer: "footer",
  "form-container": "form-container",
  form: "form",
  header: "header",
  help: "help",
  testimonials: "testimonials",
  tutorials: "tutorials",
  wedo: "wedo",
  whyus: "whyus"
};
