import "./textinput.scss";

const TextInput = ({ showTooltip,tooltip, placeholder, type, field, onChange, ...rest }) => {
  return (
    <div className='textInput'>
      <input
        {...rest}
        placeholder={placeholder}
        type={type}
        onChange={e => {
          onChange && onChange(field, e.target.value);
        }}
      />
     {showTooltip && <div className='textInput-tooltip'>{tooltip}</div>}
    </div>
  );
};

export default TextInput;
